
import LightLinearLogo from '../../assets/logos/light-linear.svg';
import LandingImg from '../../assets/quotes-landing.jpg';
import scheduleIcon from '../../assets/schedule-icon.png';
import planIcon from '../../assets/plan-icon.png';
import enjoyIcon from '../../assets/enjoy-icon.png';

import {AiFillCheckCircle} from 'react-icons/ai';

import emailjs from '@emailjs/browser';
import {useRef, useState} from 'react';



import {
    Link
  } from 'react-router-dom';

  const Fade = require("react-reveal/Fade");

function Quotes() {
    const [FormSent, setFormSent] = useState(false);
    const form = useRef();
 
 const sendEmail = (e) => {
   e.preventDefault(); // prevents the page from reloading when you hit “Send”
 
   emailjs.sendForm('service_8hejcfi', 'Quote_Request_Template', form.current, 'i2TpCv8qFnKHSfl7x')
     .then((result) => {
         setFormSent(true);
     }, (error) => {
         // show the user an error
     });
    };

    return (
        <div id="Quotes" className="Quotes">
            
            <div className="Landing" id="Landing" >
            <img className="Landing-Img" src={LandingImg}/>
            <div className="Landing-Content">
                <h1 className="Landing-Title">Getting A Quote Is Easy!</h1>
                <p className="Landing-Text">Our landscaping experts are ready to bring your vision to life. Get in touch with us today to receive a personalized quote for your project. Let's create a beautiful landscape together!</p>
            </div>
             </div>
             <div className="Section">
                    <div className="Section-Infographic">
                        <h2 className="Section-Infographic-Title">The Process Is Simple</h2>
                        <div className="Section-Infographic-Content">
                            <Fade left>
                                <div className="Section-Infographic-Content-Group">
                                    <img src={scheduleIcon}/>
                                        <div className="Section-Infographic-Content-Group-Text">
                                            <h3>Schedule</h3>
                                            <span>Our scheduling process is user-friendly, fast, and convenient, making it easy for customers to book appointments hassle-free.</span>
                                        </div>
                                    
                                </div>
                            </Fade>
                            <Fade left>
                                <div className="Section-Infographic-Content-Group">
                                    <img src={planIcon}/>
                                    <div className="Section-Infographic-Content-Group-Text">
                                        <h3>Plan</h3>
                                        <span>Our streamlined planning process ensures an effortless experience for our customers from start to finish.</span>
                                    </div>
                                </div>
                            </Fade>
                            <Fade left>
                                <div className="Section-Infographic-Content-Group">
                                    <img src={enjoyIcon}/>
                                    <div className="Section-Infographic-Content-Group-Text">
                                        <h3>Enjoy</h3>
                                        <span>Finally, sit back and let us do the hard work for you so you can reap the benefits of beautiful landscape.</span>
                                    </div>
                                </div>
                            </Fade>
                            </div>
                        </div>
                    {FormSent ?
                        <Fade>
                            <div className="Section-Appreciation">
                                <AiFillCheckCircle/>
                                <h2>Thank you for submiting a quote request</h2>
                                <span>We will be in contact with you shortly</span>
                            </div>
                        </Fade>
                    :
                    <form ref={form} onSubmit={sendEmail} className="Section-Form">
                            <div className="Section-Form-Group">
                                <h3 className="Section-Form-Group-Title">Name</h3>
                                    <div className="Row">
                                        <label id="FirstName">
                                        <input name="FirstName"  className="Section-Form-Item" type="text"/>
                                        First</label>
                                        <label id="LastName">
                                        <input name="LastName"  className="Section-Form-Item" type="text"/>
                                        Last</label>
                                    </div>
                            </div>
                            <div className="Section-Form-Group">
                                <h3 className="Section-Form-Group-Title">Details</h3>
                                    <div className="Row">
                                        <label id="JobType">
                                         <select  defaultValue="Select" name="JobType" className="Section-Form-Item">
                                            <option value="Select" disabled >Select A Type</option>
                                            <option value="Pressure Washing">Pressure Washing</option>
                                            <option value="Gutter Cleaning">Gutter Cleaning</option>
                                            <option value="Roof Repair">Roof Repair</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        Job Type</label>
                                        
                                        
                                    </div>
                                    <div className="Row">
                                        <label id="JobDescription">
                                         <textarea name="JobDescription" className="Section-Form-Item"></textarea>
                                        Job Description</label>
                                        </div>
                            </div>
                            <div className="Section-Form-Group">
                                <h3 className="Section-Form-Group-Title">Address</h3>
                                    <label id="StreetAddress">
                                    <input name="StreetAddress"  className="Section-Form-Item" type="text"/>
                                    Street Address</label>
                                    <div className="Row">
                                        <label id="City">
                                        <input name="City"  className="Section-Form-Item" type="text"/>
                                        City</label>
                                        <label id="State">
                                        <input name="State"  className="Section-Form-Item" type="text"/>
                                        State</label>
                                        <label id="Zipcode">
                                        <input name="Zipcode"  className="Section-Form-Item" type="text"/>
                                        Zipcode</label>
                                    </div>
                                    
                            </div>
                            <div className="Section-Form-Group">
                            <h3 className="Section-Form-Group-Title">Contact</h3>
                            <div className="Row">
                                
                                <label id="Phone">
                                <input name="Phone" className="Section-Form-Item" type="tel"/>
                                        Phone</label>
                                   
                                        <label id="Email">
                                    <input name="Email" className="Section-Form-Item" type="email"/>
                                    Email Address</label>
                                    </div>
                                    
                            </div>
                            <input id="Submit" className="Section-Form-Item Btn" value="Request Your Quote" type="Submit"/>
                        </form>
                    
                    }
                    
             </div>
        </div>
    );
}
export default Quotes;
