
import LightAboutImg from '../../assets/Light-About-Img.png';
import LawnMowerIcon from '../../assets/lawnmower-icon.png';
import PowerWashIcon from '../../assets/powerwash-icon.png';
import RoofingIcon from '../../assets/roofing-icon.png';
import HaulingIcon from '../../assets/hauling-icon.png';
import JunkIcon from '../../assets/junk-icon.png';
import GutterIcon from '../../assets/gutter-icon.png';
import LandingImg from '../../assets/landing.jpg';


import {
    Link
  } from 'react-router-dom';

const Fade = require("react-reveal/Fade")

function Home() {
  return (
    <div className="Home" id="Home">
        
        <div className="Landing" id="Landing" >
            <img className="Landing-Img" src={LandingImg}/>
            <div className="Landing-Content">
                <h1 className="Landing-Title">Quality Is Our Promise</h1>
                <p className="Landing-Text">We are passionate about delivering high-quality landscaping services to enhance the beauty and functionality of your outdoor space. Our motto is simple: Quality is important, and we stand by it in every project we undertake.</p>
                <Link to="/quotes" className="Landing-Btn Btn">Get A Quote</Link>
            </div>
        </div>
        <div className="Services" id="Services">
            <span className="Services-Descriptor Descriptor">Services</span>
            <h2 className="Services-Title Title">What We Provide</h2>
            <p className="Services-Text Text">Transform your outdoor space with our variety of landscaping services, including design, installation, maintenance, and more. Let us bring your vision to life.</p>
            <div className="Services-Content">
                <Fade bottom>
                <div className="Services-Card">
                    <img src={LawnMowerIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Lawn care</h3>
                    <p className="Card-Text">Our lawn care services include but are not limited to: lawn mowing, edging, trimming, weed control, and leaf removal
</p>
                </div>
                </Fade>
                <Fade top>
                <div className="Services-Card">
                    <img src={PowerWashIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Pressure Washing</h3>
                    <p className="Card-Text">Revitalize your outdoor space with our pressure washing service - perfect for driveways, patios, and siding.</p>
                </div>
                </Fade>
                <Fade right>
                <div className="Services-Card">
                    <img src={RoofingIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Roof Repair</h3>
                    <p className="Card-Text">We offer professional roof repair services to protect your home from leaks and damage. Trust us to keep your roof in top condition.</p>
                </div>
                </Fade>
                <Fade left>
                <div className="Services-Card">
                    <img src={HaulingIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Resource Hauling</h3>
                    <p className="Card-Text">Efficiently move soil, plants, and landscaping materials with our reliable Resource Hauling service.</p>
                </div>
                </Fade>
                <Fade bottom>
                <div className="Services-Card">
                    <img src={JunkIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Junk Removal</h3>
                    <p className="Card-Text">Clear out clutter with our junk removal service - fast, efficient, and affordable for your landscaping needs.</p>
                </div>
                </Fade>
                <Fade right>
                <div className="Services-Card">
                    <img src={GutterIcon} className="Card-Img"/>
                    <h3 className="Card-Title">Gutter Maintenance</h3>
                    <p className="Card-Text">Keep your gutters clean and flowing smoothly with our expert maintenance service for a pristine and protected landscape.</p>
                </div>
                </Fade>
            </div>
        </div>
        <div className="About" id="About">
                <Fade>
                <img className="About-Img" src={LightAboutImg}/>
                </Fade>
                <div className="About-Content">
                <Fade bottom>
                <span className="About-Descriptor Descriptor">About</span>

                <h2 className="About-Title Title">Who Are We?</h2>

                <p className="About-Text Text">We specialize in creating and maintaining beautiful outdoor spaces for both residential and commercial properties. Our team is dedicated to providing exceptional service and exceeding our clients' expectations. Contact us today to learn more about how we can transform your outdoor living space.</p>
                </Fade>
            </div>
        </div>
        <div className="Contact" id="Contact">
            <Fade bottom cascade>
            <div className="Contact-Content">
                <span className="Contact-Descriptor Descriptor">Contact</span>
                <h2 className="Contact-Title Title">Talk With Us!</h2>
                <p className="Contact-Text Text">Get in touch with us to discuss your landscaping needs! Whether you're looking for a complete redesign or simply need maintenance services, our team is here to help. Fill out our quote form or give us a call to schedule a consultation.</p>
            </div>
            </Fade>
            <Fade right>
            <div className="Contact-Card">
                <h4>Call Us</h4>
                <p>317.675.0949</p>
                <h4>Location</h4>
                <p>75 S 10th Ave</p>
                <p>Beech Grove, IN</p>
                <h4>Business Hours</h4>
                <p>Weekdays: 7am-5pm</p>
                <p>Weekends: 11am-1pm</p>
            </div>
            </Fade>
        </div>
        
    </div>
  );
}

export default Home;
