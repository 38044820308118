import Home from './components/home/home';
import Quotes from './components/quotes/quotes';
import './App.scss';

import LightLinearLogo from './assets/logos/light-linear.svg';
import DarkLinearLogo from './assets/logos/dark-linear.svg';

import {RiInstagramFill, RiFacebookCircleFill} from 'react-icons/ri';
import {SiThumbtack} from 'react-icons/si'
import {MdMenu} from 'react-icons/md'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {useState} from 'react'

function App() {
  const [NavActive, setNavActive] = useState(false);

  return (
    <Router>
      <div className="App">
      <div className={"Nav " + (NavActive ? 'active' : '')}>
                <img className={"Nav-Logo " + (NavActive ? 'active' : '')} src={LightLinearLogo}/>
                <a id="Nav-Collapse" className={(NavActive ? 'active' : '')} onClick={() => setNavActive(!NavActive)}><MdMenu/></a>
                <div className={'Nav-Content ' + (NavActive ? 'active' : '')}> 
                <Link onClick={() => {window.scrollTo(0, 0); setNavActive(!NavActive)}} to="/" className="Nav-Item">Home</Link>
                <HashLink onClick={() => setNavActive(!NavActive)} to="/#Services" className="Nav-Item">Services</HashLink>
                <HashLink onClick={() => setNavActive(!NavActive)} to="/#About" className="Nav-Item">About</HashLink>
                <HashLink onClick={() => setNavActive(!NavActive)} to="/#Contact" className="Nav-Item">Contact</HashLink>
                <Link onClick={() => {window.scrollTo(0, 0); setNavActive(!NavActive)}} to="/quotes" className="Nav-Item Btn">Quotes</Link>
                </div>
            </div>
        <Routes>
                 <Route exact path='/' element={< Home />}></Route>
                 <Route exact path='/quotes' element={< Quotes />}></Route>
          </Routes>
          <div className="Footer" >
            <div className="Footer-Links">

            </div>
            
              <div className="Footer-Row">
              <div className="Footer-Column">
                <h4>Company</h4>
                <Link onClick={() => {window.scrollTo(0, 0);}} to="/" className="Footer-Column-Item">Home</Link>
                <HashLink  to="/#Services" className="Footer-Column-Item">Services</HashLink>
                <HashLink  to="/#About" className="Footer-Column-Item">About</HashLink>
                <HashLink  to="/#Contact" className="Footer-Column-Item">Contact</HashLink>
                <Link onClick={() => {window.scrollTo(0, 0);}} to="/quotes" className="Footer-Column-Item">Quotes</Link>
              </div>
              <div className="Footer-Column">
                <h4>Quick Links</h4>
                <Link onClick={() => {window.scrollTo(0, 0);}} to="/" className="Footer-Column-Item">Home</Link>
                <HashLink  to="/#Services" className="Footer-Column-Item">Services</HashLink>
                <HashLink  to="/#About" className="Footer-Column-Item">About</HashLink>
                <HashLink  to="/#Contact" className="Footer-Column-Item">Contact</HashLink>
                <Link onClick={() => {window.scrollTo(0, 0); }} to="/quotes" className="Footer-Column-Item">Quotes</Link>
              </div>
              </div>
              <div className="Footer-Row">
                <div className="Footer-Column">
                <h4>Let's Stay In Touch!</h4>
                <div className="Footer-SocialIcons">
                    <a target="_blank" href="https://www.instagram.com/greens.cleans.indy/"><RiInstagramFill/></a>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100091273442688"><RiFacebookCircleFill/></a>
                    <a target="_blank" href="https://www.thumbtack.com/in/beech-grove/pressure-washing/greens-cleans/service/478376458165239811?utm_medium=web&utm_source=txt&surface=sp"><SiThumbtack/> </a>
                    </div>
                </div>
                
            </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
